<template>
  <v-card elevation="0" color="transparent">
    <v-overlay absolute opacity="0.2" :value="loadingSetAc">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
    <div class="pa-4">
      <div class="font-weight-bold accent--text pb-3">IR開關</div>
      <div class="d-flex">
        <v-item-group active-class="primary white--text">
          <div class="d-inline-flex ems-blue-grey-two rounded pa-1">
            <div
              v-for="(opt, index) in statusOption"
              :key="opt.value"
              :class="index === statusOption.length - 1 ? '' : 'mr-1'"
            >
              <v-item :value="opt.value === 'POWER_ON'">
                <v-card
                  class="
                    d-flex
                    align-center
                    status-control
                    noState-hover
                    white--text
                    ems-light-grey-blue
                  "
                  hover
                  width="62px"
                  height="62px"
                  @click="controlAC(opt.value)"
                >
                  <div class="text-center ma-auto">
                    <v-icon dark style="transform: rotate(90deg)">
                      {{ opt.icon }}
                    </v-icon>
                    <div class="ma-1 subtitle-2">{{ opt.text }}</div>
                  </div>
                </v-card>
              </v-item>
            </div>
          </div>
        </v-item-group>
        <div class="ems-bluegrey--text ml-4 d-flex align-center">
          <div>
            按下開關後，系統無法得知冷氣是否確實開/關，請透過其他方式確認
          </div>
        </div>
      </div>
      <v-sheet
        v-if="history.power && history.powerRecordTimes"
        rounded="md"
        class="pa-3 accent--text mt-3 font-weight-bold"
        color="#abc0de4c"
      >
        <v-icon color="accent" small class="mr-2"
          >mdi-clipboard-text-clock-outline</v-icon
        >
        <span>
          {{ moment(history.powerRecordTimes).format('YYYY-MM-DD HH:mm') }}
          已選擇執行「{{ statusText }}」</span
        >
      </v-sheet>
    </div>

    <v-divider />
    <div class="pa-4">
      <div class="d-flex justufy-space-between">
        <div class="font-weight-bold accent--text pb-2">溫度</div>
      </div>
      <div class="mt-2 tmp-control">
        <v-slider
          v-if="!$vuetify.breakpoint.xs"
          :value="settingsTemperature"
          :tick-labels="temperatureOptionSlide"
          min="0"
          :max="temperatureOption.length - 1"
          ticks="always"
          color="primary"
          :tick-size="temperatureOption.length"
          @change="setAcTemperature($event)"
        >
          <template v-slot:thumb-label="props">
            <div class="caption">
              {{ getTemp(props.value) }}
            </div>
          </template>
        </v-slider>
        <v-select
          v-else
          :value="settingsTemperature"
          :items="temperatureOptionSlide"
          outlined
          color="primary"
          background-color="white"
          hide-details
          @change="setAcTemperature($event)"
        ></v-select>
        <v-sheet
          v-if="history.temperature && history.temperatureRecordTimes"
          rounded="md"
          class="pa-3 accent--text mt-3 font-weight-bold"
          color="#abc0de4c"
        >
          <v-icon color="accent" small class="mr-2"
            >mdi-clipboard-text-clock-outline</v-icon
          >
          <span>
            {{
              moment(history.temperatureRecordTimes).format('YYYY-MM-DD HH:mm')
            }}
            已選擇執行「{{ tempText }}」</span
          >
        </v-sheet>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AC from '@/api/ems/AC'

export default {
  props: {
    controlId: {
      type: String,
      default: ''
    },
    isGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      temperature: null,
      history: {},
      loadingSetAc: false,
      settingsTemperature: 0
    }
  },
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      temperatureOption: 'ac/temperatureOption'
    }),
    temperatureOptionSlide() {
      return this.temperatureOption.map((d) => d.text)
    },
    statusText() {
      return this.statusOption.find(({ value }) => value === this.history.power)
        .text
    },
    tempText() {
      return this.temperatureOption.find(
        ({ value }) => value === this.history.temperature
      ).text
    }
  },
  mounted() {
    this.getControlHistory()
  },
  methods: {
    getTemp(val) {
      return this.temperatureOptionSlide[val]
    },
    setAcTemperature(temp) {
      this.settingsTemperature = temp
      const type =
        this.temperatureOption[temp].value || this.temperatureOption[0].value
      this.controlAC(type)
    },
    async controlAC(type) {
      try {
        this.loadingSetAc = true
        if (this.isGroup) await AC.controlGroupAc(this.controlId, type)
        else await AC.controlAc(this.controlId, type)
        this.getControlHistory()
      } catch (error) {
        let errorMessage = '設定失敗'
        if (error.response && error.response.data.errorMessage) {
          errorMessage = error.response && error.response.data.errorMessage
        }
        this.showMsg({
          message: errorMessage,
          closable: true,
          time: 3000,
          color: 'error'
        })
      } finally {
        this.loadingSetAc = false
        this.settingsTemperature = 0
      }
    },
    async getControlHistory() {
      try {
        const { data } = this.isGroup
          ? await AC.getGroupControlHistory(this.controlId)
          : await AC.getControlHistory(this.controlId)
        this.history = data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.noState-hover {
  &:hover {
    background: var(--v-ems-blue-base) !important;
  }
}
</style>
