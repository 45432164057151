import { render, staticRenderFns } from "./IRControl.vue?vue&type=template&id=1a87d2a4&scoped=true&"
import script from "./IRControl.vue?vue&type=script&lang=js&"
export * from "./IRControl.vue?vue&type=script&lang=js&"
import style0 from "./IRControl.vue?vue&type=style&index=0&id=1a87d2a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a87d2a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VCard,VDivider,VIcon,VItem,VItemGroup,VOverlay,VProgressCircular,VSelect,VSheet,VSlider})
