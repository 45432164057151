import settings from '@/settings'

/**
 * 讀卡機資料
 */
class CardReader {
  /**
   * 即時資訊
   */
  getCard(id) {
    return axios.get(settings.api.fullPath + `/ems/cardreader/${id}`)
  }

  /**
   * 卡機歷史資訊
   */
  history(id) {
    return axios.get(settings.api.fullPath + `/ems/cardreader/${id}/history`)
  }
  /**
   * 取得班級所有卡機
   */
  roomCards(classId) {
    return axios.get(settings.api.fullPath + `/ems/cardreader/class/${classId}`)
  }

  /**
   * 設定班級卡機費率
   */
  setClassCardreaderRate(classId, ids, rate) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/control/rate/class/${classId}`,
      {
        ids: ids,
        rate: rate
      },
      {
        validateStatus: function (status) {
          return (
            (status >= 200 && status < 300) || status === 502 || status === 504
          )
        }
      }
    )
  }

  /**
   * 設定學校卡機費率
   */
  setSchoolCardreaderRate(schoolId, ids, rate) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/control/rate/school/${schoolId}`,
      {
        ids: ids,
        rate: rate
      },
      {
        validateStatus: function (status) {
          // return status >= 200 && status < 300 // default
          return (
            (status >= 200 && status < 300) || status === 502 || status === 504
          )
        }
      }
    )
  }
  /**
   * 取得學校所有卡機資料
   */
  getSchoolCardreader(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/cardreader/raw/school/${schoolId}`
    )
  }

  /**
   * 取得班級卡機即時資訊
   */
  roomRealtime(classId) {
    return axios.get(
      settings.api.fullPath + `/ems/cardreader/class/${classId}/realtime`
    )
  }

  /**
   * Relay 班級下控
   */
  setRelay(id, type) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/controller/${id}`,
      {},
      {
        params: {
          type: type
        }
      }
    )
  }

  /**
   * new 控制電驛
   */
  setRelayMode(id, type) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/control/${id}/type/${type}`
    )
  }

  /**
   * Relay 群組下控
   */
  setGroupRelay(groupId, type) {
    return axios.post(
      settings.api.fullPath + `/ems/cardreader/controller/group/${groupId}`,
      {},
      {
        params: {
          type: type
        }
      }
    )
  }

  /**
   * new 群組控制電驛
   */
  setGroupRelayMode(groupId, type) {
    return axios.post(
      settings.api.fullPath +
        `/ems/cardreader/control/group/${groupId}/type/${type}`
    )
  }
}

var cardReader = new CardReader()
export default cardReader
