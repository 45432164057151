<template>
  <v-card
    elevation="0"
    width="100%"
    color="rgba(255, 255, 255, 0)"
    class="ACControl mt-2"
  >
    <v-overlay absolute opacity="0.2" :value="loadingSetAc">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
    <v-list color="rgba(255, 255, 255, 0)">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 font-weight-bold">
            開關
          </v-list-item-title>
          <div class="ml-4 mb-4">
            <v-switch
              v-if="!loadingSwitchAcText"
              @change="updateStatus"
              v-model="status"
              inset
              color="primary"
              hide-details
              :label="status ? '運轉中' : '關機中'"
            ></v-switch>
            <div class="mt-3" v-else>
              <v-progress-circular
                indeterminate
                size="30"
                color="grey"
                width="2"
              >
                <span v-if="countdownSec < 61">{{ countdownSec }}</span>
              </v-progress-circular>
              <span class="ml-3 grey--text">{{ loadingSwitchAcText }}</span>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 font-weight-bold">
            模式
          </v-list-item-title>
          <v-item-group
            active-class="primary white--text"
            mandatory
            :value="mode"
          >
            <div class="d-flex mt-4">
              <div
                v-for="(opt, modeIndex) in modeOption"
                :key="opt.value"
                class="mr-4"
              >
                <v-item v-slot="{ active }">
                  <v-card
                    :class="[
                      'd-flex align-center mode-control white--text',
                      { primary: active && status },
                      { 'ems-pale-grey': !status }
                    ]"
                    elevation="0"
                    width="70px"
                    height="70px"
                    @click="updateMode(modeIndex)"
                  >
                    <div class="text-center ma-auto">
                      <v-icon v-if="opt.icon" dark> {{ opt.icon }} </v-icon>
                      <v-img
                        contain
                        v-if="opt.image"
                        height="24"
                        :src="opt.image"
                      ></v-img>
                      <div class="ma-1 subtitle-2">
                        {{ opt.text }}
                      </div>
                    </div>
                  </v-card>
                </v-item>
              </div>
            </div>
          </v-item-group>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title
            class="subtitle-2 font-weight-bold d-flex justify-space-between"
          >
            <div class="">溫度</div>
            <div v-if="!status" class="primary--text">--</div>
            <div v-if="status && temperature > -1" class="primary--text">
              {{ temperatureOption[temperature].text || '' }}
            </div>
          </v-list-item-title>
          <div class="mt-4 px-3 tmp-control">
            <v-slider
              v-if="!$vuetify.breakpoint.xs"
              :disabled="
                !status ||
                !['FUNCTION_COOL', 'FUNCTION_HEATER'].includes(ac.mode)
              "
              :value="temperature"
              :tick-labels="temperatureOptionSlide"
              min="0"
              :max="temperatureOption.length - 1"
              ticks="always"
              color="primary"
              :tick-size="temperatureOption.length"
              @change="updateTemp($event)"
            >
              <template v-slot:thumb-label="props">
                <div class="caption">
                  {{ getTemp(props.value) }}
                </div>
              </template>
            </v-slider>
            <v-select
              v-else
              :disabled="
                !status ||
                !['FUNCTION_COOL', 'FUNCTION_HEATER'].includes(ac.mode)
              "
              :value="temperature"
              :items="temperatureOptionSlide"
              outlined
              color="primary"
              background-color="white"
              @change="updateTemp($event)"
            ></v-select>
          </div>
        </v-list-item-content>
      </v-list-item>
      <template
        v-if="!updateValue.fantaiseiamax || updateValue.fantaiseiamax === 0"
      >
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-bold">
              風量
            </v-list-item-title>
            <v-item-group active-class="primary white--text" :value="fan">
              <!-- 單台一般風量控制 -->
              <div class="d-flex mt-4">
                <v-item
                  v-slot="{ active }"
                  v-for="(opt, fanIndex) in fanOption"
                  :key="opt.value"
                  class="mr-4"
                >
                  <v-btn
                    elevation="0"
                    :class="[
                      'mode-control white--text',
                      { primary: active && status },
                      { 'ems-pale-grey': !status }
                    ]"
                    width="70px"
                    height="70px"
                    @click="updateFan(fanIndex)"
                  >
                    {{ opt.text }}
                  </v-btn>
                </v-item>
              </div>
            </v-item-group>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        v-if="updateValue.fantaiseiamax && updateValue.fantaiseiamax > 0"
      >
        <v-divider></v-divider>
        <v-list-item two-line>
          <!-- 僅設單台 Taiseia 風量控制 -->
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-bold">
              風量
            </v-list-item-title>
            <div class="mt-4 px-3 tmp-control">
              <TaiseiaFanSlider
                v-model="fantaiseia"
                :maxLength="Number(updateValue.fantaiseiamax)"
                :disabled="!status"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import TaiseiaFanSlider from '@/components/TaiseiaFanSlider'
import { mapGetters, mapActions } from 'vuex'
import AC from '@/api/ems/AC'
import Device from '@/api/ems/Device'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export default {
  name: 'ACControl',
  components: {
    TaiseiaFanSlider
  },
  props: {
    ac: {
      type: Object,
      default: () => ({
        status: 'POWER_OFF',
        mode: '',
        temperature: '',
        fan: '',
        fantaiseia: '',
        fantaiseiamax: 15
      })
    },
    classroomId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    updateValue: {
      status: 'POWER_OFF',
      mode: '',
      temperature: '',
      fan: '',
      fantaiseia: '',
      fantaiseiamax: 15
    },
    loadingAcPhase1: false,
    loadingAcPhase2: false,
    loadingCloseAcPhase1: false,
    loadingCloseAcPhase2: false,
    countdownSec: 61,
    groupAcCountdownSec: 61,
    groupAcTimer: null,
    timer: null,
    loadingSetAc: false
  }),
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption',
      fanOption: 'ac/fanOption',
      updatingAcs: 'action/updatingAcs'
    }),
    temperatureOptionSlide() {
      return this.temperatureOption.map((d) => d.text)
    },
    status: {
      get() {
        return (
          this.updateValue.status === 'POWER_ON' &&
          !this.loadingAcPhase1 &&
          !this.loadingAcPhase2
        )
      },
      set() {}
    },
    loadingSwitchAcText() {
      if (this.loadingAcPhase1 && this.updateValue.status === 'POWER_ON')
        return '控制指令傳送中'
      if (this.loadingAcPhase2 && this.updateValue.status === 'POWER_ON')
        return '冷氣關機中'
      if (this.loadingAcPhase1 && this.updateValue.status === 'POWER_OFF')
        return '控制指令傳送中'
      if (this.loadingAcPhase2 && this.updateValue.status === 'POWER_OFF')
        return '冷氣開機中'
      return ``
    },
    mode: {
      get() {
        const modes = this.modeOption.map((d) => d.value)
        return modes.indexOf(this.updateValue.mode) || 0
      },
      set() {}
    },
    temperature() {
      let tem = String(this.updateValue.settingTemperature)
      const responseTemps = this.temperatureOption.map((d) => d.acResponseValue)
      if (responseTemps.indexOf(tem) >= 0) {
        return responseTemps.indexOf(tem)
      }
      const valueTemps = this.temperatureOption.map((d) => d.value)
      if (valueTemps.indexOf(tem) >= 0) {
        return valueTemps.indexOf(tem)
      }
      return 0
    },
    fan() {
      const fans = this.fanOption.map((d) => d.value)
      return fans.indexOf(this.updateValue.fan) || null
    },
    fantaiseia: {
      get: function () {
        return this.updateValue.fantaiseia
      },
      set: function (val) {
        if (this.updateValue.fantaiseia === val) {
          return
        }
        this.updateTaiseiaFan(val)
      }
    }
  },
  watch: {
    ac: function (val) {
      this.updateValue = val
    },
    updatingAcs: {
      deep: true,
      handler(val) {
        if (val.length) {
          let exist = val.find((ac) => ac.id === this.ac.id)
          if (exist) {
            this.updatingAndCheck(exist.type)
          }
        }
      }
    }
  },
  mounted() {
    this.updateValue = this.ac
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      updateClassroom: 'action/updateClassroom',
      setUpdatingAcs: 'action/setUpdatingAcs',
      finishAcUpdating: 'action/finishAcUpdating'
    }),
    getTemp(val) {
      return this.temperatureOptionSlide[val]
    },
    async updatingAndCheck(checkType) {
      if (!this.loadingSetAc) {
        this.$emit('operateAc', {
          id: this.ac.id,
          status: true
        })
        this.loadingSetAc = true
        if (checkType === 'POWER_ON' || checkType === 'POWER_OFF') {
          this.loadingAcPhase1 = true
          this.timer = setInterval(() => {
            if (this.countdownSec === 45) {
              this.loadingAcPhase1 = false
              this.loadingAcPhase2 = true
            }
            if (this.countdownSec) this.countdownSec -= 1
            else {
              clearInterval(this.timer)
              this.countdownSec = 61
            }
          }, 1000)
        }
        const result = await this.checkClassroomInfoTimes()
        if (result) {
          if (checkType === 'POWER_ON' || checkType === 'POWER_OFF') {
            clearInterval(this.timer)
            this.countdownSec = 61
          }
        } else {
          // phase 2 to get new classroom info per 10 sec for 6 times
          this.$emit('setAcPhase2Loading', true)
          await this.checkClassroomInfoTimes(6, 10)
        }
        this.finishAcUpdating(this.ac.id)
        this.loadingAcPhase1 = false
        this.loadingAcPhase2 = false
        this.$emit('operateAc', {
          id: this.ac.id,
          status: false
        })
        this.loadingSetAc = false
      }
    },
    async checkClassroomInfoTimes(times = 3, delaySeconds = 20) {
      if (!times) return false
      await delay(delaySeconds * 1000)
      const result = await this.getClassroomInfo(times)
      if (result) {
        return true
      } else {
        return await this.checkClassroomInfoTimes(times - 1, delaySeconds)
      }
    },
    async getClassroomInfo() {
      try {
        let type = this.updatingAcs.find((ac) => ac.id === this.ac.id).type
        const { data } = await Device.getClassroomDevices(this.classroomId)
        this.updateClassroom(data)
        console.log(
          `target: ${type}, get:`,
          data.airConditioners.find(({ id }) => id === this.ac.id)
        )
        let compareResult = type.includes('TEMP')
          ? data.airConditioners.find(({ id }) => id === this.ac.id)
              .settingTemperature === Number(type.split('TEMP_')[1])
          : Object.values(
              data.airConditioners.find(({ id }) => id === this.ac.id)
            ).includes(type)
        if (compareResult) {
          return true
        } else return false
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async updateStatus(val) {
      const acStatus = val ? 'POWER_ON' : 'POWER_OFF'
      this.setIR('status', acStatus)
    },
    updateMode(val) {
      const type = this.modeOption[val]
      if (val !== undefined && this.status) {
        this.setIR('mode', type.value)
      }
    },
    updateFan(val) {
      const type = this.fanOption[val]
      if (val !== undefined && this.status) {
        this.setIR('fan', type.value)
      }
    },
    updateTemp(val) {
      const type =
        this.temperatureOption[val].value || this.temperatureOption[0].value
      this.setIR('temperature', type)
    },
    updateTaiseiaFan(val) {
      this.setIR('fantaiseia', val)
    },
    setIR(key, value) {
      this.controlAC(value)
    },
    async controlAC(type) {
      this.setUpdatingAcs({
        acs: [this.ac],
        type
      })
      try {
        await AC.controlAc(this.ac.id, type)
      } catch (error) {
        if (error.response.status !== 502 && error.response.status !== 504) {
          let errorMessage = '設定失敗'
          if (error.response && error.response.data.errorMessage) {
            errorMessage = error.response && error.response.data.errorMessage
          }
          this.showMsg({
            message: errorMessage,
            closable: true,
            time: 3000,
            color: 'error'
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ACControl .mode-control {
  border-color: transparent;
  background: var(--v-ems-light-grey-blue-base);
}
</style>
