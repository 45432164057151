import settings from '@/settings'
import axios from 'axios'

/**
 * 冷氣資訊
 */
class AC {
  /**
   * 取得班級所有冷氣即時資訊
   */
  getClass(classId) {
    return axios.get(settings.api.fullPath + `/ems/ac/class/${classId}`)
  }

  /**
   * 取得學校所有班級的冷氣即時資訊
   */
  // getSchool(schoolId) {
  //   return axios.get(settings.api.fullPath + `/ems/ac/school/${schoolId}`)
  // }

  /**
   * 控制指定冷氣
   */
  control(deviceId, params) {
    return axios.post(
      settings.api.fullPath + `/ems/ac/controller/${deviceId}`,
      {},
      {
        params: params
      }
    )
  }

  /**
   * new 控制指定冷氣
   */
  controlAc(id, type) {
    return axios.post(
      settings.api.fullPath + `/ems/ac/control/${id}/type/${type}`
    )
  }

  /**
   * 控制指定冷氣群組
   */
  controlGroup(groupId, params) {
    return axios.post(
      settings.api.fullPath + `/ems/ac/controller/group/${groupId}`,
      {},
      {
        params: params
      }
    )
  }

  /**
   * new 控制群組冷氣
   */
  controlGroupAc(groupId, type) {
    return axios.post(
      settings.api.fullPath + `/ems/ac/control/group/${groupId}/type/${type}`,
      null,
      {
        validateStatus: function (status) {
          return (
            (status >= 200 && status < 300) || status === 502 || status === 504
          )
        }
      }
    )
  }

  /**
   * 取得班級冷氣歷史資訊
   */
  getACHistory(id, params) {
    return axios.get(settings.api.fullPath + `/ems/ac/history/${id}`, {
      params: params
    })
  }
  /**
   * 取得班級冷氣歷史資訊
   */
  getClassHistory(classId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/ac/class/history/${classId}`,
      {
        params: params
      }
    )
  }
  /**
   * 溫度濕度
   * 取得學校溫度歷史資訊
   */
  getSchoolEnvHistory(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/ac/school/history/${schoolId}/average`,
      {
        params: params
      }
    )
  }
  /**
   * 溫度濕度
   * 取得班級溫度歷史資訊
   */
  getClassEnvHistory(classId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/ac/class/history/${classId}/average`,
      {
        params: params
      }
    )
  }

  /**
   * 查詢冷氣控制紀錄
   */
  getControlHistory(acId) {
    return axios.get(settings.api.fullPath + `/ems/ac/control/${acId}/record`)
  }

  /**
   * 查詢冷氣控制群組紀錄
   */
  getGroupControlHistory(groupId) {
    return axios.get(
      settings.api.fullPath + `/ems/ac/control/group/${groupId}/record`
    )
  }
}

var ac = new AC()
export default ac

// 資料查詢
//     選單部分
//         /group/simple/{schoolId} 查詢群組簡易資料
//         /config/building/class/{schoolId} 查詢大樓至教室層級
//         /config/building/full/{schoolId} 查詢完整大樓資料 含設備
//     冷氣部分
//         /ems/ac/history/{id} 取得冷氣歷史資訊
//         /ems/ac/class/history/{classId} 取得班級冷氣歷史資訊
//         /ems/ac/history 取得冷氣歷史資訊 (多筆)
//         /ems/ac/class/history 取得班級冷氣歷史資訊 (多筆)
