var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-auto ems-opacity-50-bg frosted-glass",attrs:{"rounded":"","elevation":"0","height":"100%","width":"100%"}},[_c('v-card-title',{staticClass:"pb-2"},[_c('span',{staticClass:"subtitle-1 font-weight-bold accent--text"},[_vm._v(" 群組 ")]),_c('v-spacer'),_c('GroupAppliedHint')],1),(_vm.loadingGroups)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.filteredGroups.length > 0)?_c('v-card-text',[_c('v-expansion-panels',{attrs:{"flat":"","focusable":""}},_vm._l((_vm.filteredGroups),function(item){return _c('v-expansion-panel',{key:item.id,class:[
                'ac-expansion-panel',
                {
                  'ir-control': item.type === 'IR_AIR_CON',
                  established: item.type === 'CARD_READER'
                }
              ],attrs:{"elevation":"0","active-class":"active"},on:{"change":function($event){return _vm.selectItem(item, 'group')}}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-arrow-down"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var open = ref.open;
return [_c('div',{class:[
                    'd-flex  align-center font-weight-bold',
                    { 'white--text': open },
                    { ' accent--text': !open }
                  ]},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"ml-1"},[_c('GroupApply',{attrs:{"group":_vm.getOriginGroup(item)}})],1),_c('v-spacer'),(item.statusMap)?_c('div',{staticClass:"d-flex align-center justify-end mr-3"},[_c('v-icon',{staticClass:"mx-1",attrs:{"x-small":"","color":['PARTIALLY_ON', 'ON'].includes(
                          item.statusMap.AIRCONDITIONER
                        )
                          ? 'success'
                          : 'error'}},[_vm._v(" mdi-checkbox-blank-circle ")]),(item.statusMap.AIRCONDITIONER === 'OFF')?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center mr-3"},[_c('div',{class:[
                            'ml-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]},[_vm._v(" 關閉 ")])])]):_vm._e()],1):_vm._e(),(false)?_c('div',{staticClass:"d-flex align-center justify-end mr-3"},[_c('v-icon',{staticClass:"mx-1",attrs:{"x-small":"","color":item.status.status === 'POWER_ON'
                          ? 'success'
                          : 'error'}},[_vm._v(" mdi-checkbox-blank-circle ")]),(item.status.status !== 'POWER_OFF')?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center mr-3"},[_c('div',{class:[
                            'ml-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]},[_vm._v(" 關閉 ")])])]):_vm._e(),(item.status.status === 'POWER_ON')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [(_vm.getMode(item.status.mode))?_c('div',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"title":_vm.getMode(item.status.mode).text}},'div',attrs,false),on),[(open)?_c('v-icon',{attrs:{"size":"sm","color":"white"}},[_vm._v(" "+_vm._s(_vm.getMode(item.status.mode).icon)+" ")]):_c('v-icon',{attrs:{"size":"sm","color":"accent"}},[_vm._v(" "+_vm._s(_vm.getMode(item.status.mode).icon)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getMode(item.status.mode).text))])]),(_vm.getTemperature(item.status.temperature))?_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.getTemperature(item.status.temperature).text)+" ")])]):_vm._e()]:_vm._e()],2):_vm._e()],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('GroupControlContent',{attrs:{"group":item,"classrooms":_vm.classrooms},on:{"reloadClassrooms":function($event){return _vm.getClassrooms(_vm.tagId)}}})],1)],1)}),1)],1):_c('v-card-text',{staticClass:"text-center"},[_vm._v(" 無資料")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-auto ems-opacity-50-bg frosted-glass",attrs:{"rounded":"","elevation":"0","height":"100%","width":"100%"}},[_c('v-card-title',{staticClass:"pb-2"},[_c('span',{staticClass:"subtitle-1 font-weight-bold accent--text"},[_vm._v(" 班級與專科教室 ")])]),(_vm.loadingClassrooms)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.filteredRoom.length > 0)?_c('v-card-text',[_c('v-expansion-panels',{attrs:{"flat":"","focusable":""}},_vm._l((_vm.filteredRoom),function(classroom){return _c('v-expansion-panel',{key:classroom.id,class:[
                'ac-expansion-panel',
                {
                  'ir-control': _vm.isIRClassroom(classroom),
                  established: _vm.isEstablishedClassroom(classroom)
                }
              ],attrs:{"elevation":"0","active-class":"active"},on:{"change":function($event){return _vm.selectItem(classroom, 'room')}}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-arrow-down"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var open = ref.open;
return [_c('v-row',{class:[
                    'd-flex align-center justify-space-between font-weight-bold',
                    { 'white--text': open },
                    { ' accent--text': !open }
                  ]},[_c('v-col',{attrs:{"cols":"7"}},[_c('span',[_vm._v(" "+_vm._s(classroom.name)+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_vm._v(_vm._s(_vm.relayModeText(classroom)))])]),_c('v-col',{attrs:{"cols":"3"}},[(!classroom.airConditioners)?_c('div',{staticClass:"d-flex align-center justify-end mr-3"},[_c('div',{class:[
                          'ml-2',
                          { 'white--text': open },
                          { 'grey--text': !open }
                        ]},[_vm._v(" 無冷氣資料 ")])]):_c('div',{staticClass:"d-flex align-center justify-end mr-3"},[_c('div',{staticClass:"d-flex align-center justify-center"},[(_vm.isIRClassroom(classroom))?_c('v-img',{attrs:{"contain":"","max-height":"18","max-width":"18","src":open
                              ? require('@/assets/image/acControl/ir-expand-panel.png')
                              : require('@/assets/image/acControl/ir.png')}}):(_vm.isEstablishedClassroom(classroom))?_c('v-icon',{staticClass:"mx-1",attrs:{"x-small":"","color":_vm.cardReaderIsOn(classroom) ? 'success' : 'error'}},[_vm._v(" mdi-checkbox-blank-circle ")]):_c('v-icon',{staticClass:"mx-1",attrs:{"x-small":"","color":_vm.acState(classroom.airConditioners).on > 0
                              ? 'success'
                              : 'error'}},[_vm._v(" mdi-checkbox-blank-circle ")])],1),(_vm.isIRClassroom(classroom))?_c('div',{class:[
                          'mx-2',
                          { 'white--text': open },
                          { 'grey--text': !open }
                        ]},[_vm._v(" IR控制 ")]):_vm._e(),(_vm.isEstablishedClassroom(classroom))?_c('div',{class:[
                          'mx-2',
                          { 'white--text': open },
                          { 'grey--text': !open }
                        ]},[_vm._v(" 既設冷氣 ")]):(_vm.acState(classroom.airConditioners).on > 0)?_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.acState(classroom.airConditioners).on)+" / "+_vm._s(_vm.acState(classroom.airConditioners).total)+" ")]):_c('div',{class:[
                          'mx-2',
                          { 'white--text': open },
                          { 'grey--text': !open }
                        ]},[_vm._v(" 關閉 ")])])])],1)]}}],null,true)}),(!classroom.airConditioners)?_c('v-expansion-panel-content',[_c('div',{staticClass:"grey--text text-center mt-3 pa-3"},[_vm._v("無冷氣資料")])]):_c('v-expansion-panel-content',{staticClass:"pt-3",attrs:{"eager":""}},[_c('ClassroomControlContent',{attrs:{"classroom":classroom}})],1)],1)}),1)],1):_c('v-card-text',{staticClass:"text-center"},[_vm._v(" 無資料")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }