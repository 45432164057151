<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar dense flat color="transparent">
          <v-btn
            color="#00ddcb"
            v-bind="attrs"
            v-on="on"
            class="accent--text"
            :loading="loadingSetRelay && countdownSec > 60"
            :disabled="loadingSetRelay"
          >
            <!-- todo -->
            <!-- :disabled="loadingSetRelay || controllableClassroomsInTheGroup.length === 0" -->
            <!-- <span v-if="!controllableClassroomsInTheGroup.length">
              無可控教室
            </span> -->
            <span v-if="loadingSetRelay">
              {{ countdownSec }}
            </span>
            <span v-else> 電驛開關 </span>
          </v-btn>
          <div class="ml-2 grey--text">
            <template v-if="relayText"> {{ relayText }}<br /> </template>
            <template v-else-if="relayMode !== null && isSuccess">
              <span>{{ selectRelayModeSuccessText }}</span
              ><br />
            </template>
            群組內教室電驛將會同步受控<br />
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="uncontrollableClassroomsInTheGroup.length"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-1" small>mdi-alert-outline</v-icon>
                  查看排除不可控制教室
                </span>
              </template>
              <span>
                <div>以下班級將不受群組電驛控制</div>
                <template
                  v-for="classroom in uncontrollableClassroomsInTheGroup"
                >
                  <v-icon color="white">mdi-circle-small</v-icon>
                  {{ classroom.name }}<br />
                </template>
              </span>
            </v-tooltip>
          </div>
        </v-toolbar>
      </template>
      <v-lazy>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="accent--text font-weight-bold">
              選擇由電驛來控制冷氣
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                dialog = false
                bindRelayMode = relayMode
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item-group v-model="bindRelayMode">
              <v-list-item
                v-for="(item, i) in relayActionOption"
                :key="i"
                three-line
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <div class="text--secondary">{{ item.desc }}</div>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="setClassRelay()" class="px-10">
              確認
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-lazy>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardReader from '@/api/ems/CardReader'

const checkUnControllable = ({ classroomMeters, cardReaders }) => {
  if (classroomMeters.find(({ deviceAlive }) => !deviceAlive)) return true
  if (classroomMeters.find(({ ts }) => checkOver20min(ts))) return true
  if (cardReaders.find(({ deviceAlive }) => !deviceAlive)) return true
  if (cardReaders.find(({ ts }) => checkOver20min(ts))) return true
  return false
}
const checkOver20min = (ts) => {
  return new Date() - new Date(ts) > 20 * 60 * 1000
}

export default {
  name: 'ActionGroupRelayControl',
  mixins: [],
  components: {},
  props: {
    group: {
      type: Object
    },
    loadingSetRelay: {
      type: Boolean
    }
  },
  data: function () {
    return {
      overlay: false,
      dialog: false,
      relayMode: null,
      bindRelayMode: null,
      countdownSec: 61,
      relayText: '',
      timer: null,
      isSuccess: false
    }
  },
  computed: {
    ...mapGetters({
      relayActionOption: 'ac/relayActionOption',
      classrooms: 'action/classrooms',
      updatingClassroomIds: 'action/updatingClassroomIds'
    }),
    classroomsInTheGroup() {
      return this.classrooms.filter(({ id }) =>
        this.group.classroomIds.includes(id)
      )
    },
    controllableClassroomsInTheGroup() {
      return this.classroomsInTheGroup.filter(
        (element) => !checkUnControllable(element)
      )
    },
    differentRelayModeClassrooms() {
      return this.controllableClassroomsInTheGroup.filter(
        ({ cardReaders }) => cardReaders[0].relay.mode !== this.relayMode
      )
    },
    uncontrollableClassroomsInTheGroup() {
      return this.classroomsInTheGroup.filter((element) =>
        checkUnControllable(element)
      )
    },
    updatingClassroomIdsInTheGroup() {
      return this.updatingClassroomIds.filter((cls) =>
        this.group.classroomIds.includes(cls.classroomId)
      )
    },
    updatingClassroomIdsInTheGroupLength() {
      return this.updatingClassroomIdsInTheGroup.length
    },
    selectRelayModeSuccessText() {
      switch (this.relayMode) {
        case 0:
          return '電驛斷開執行成功'
        case 1:
          return '電驛接上執行成功'
        case 2:
          return '交由卡機控制完成'
      }
    }
  },
  watch: {
    updatingClassroomIdsInTheGroupLength(val) {
      if (!val) {
        this.resetRelay()
        this.isSuccess = true
        this.$emit('setRelaySuccess', true)
      }
    },
    relayMode(val) {
      this.$emit('setRelayMode', val)
      this.isSuccess = false
      this.$emit('setRelaySuccess', false)
    }
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      setUpdatingClassroomIds: 'action/setUpdatingClassroomIds'
    }),
    resetRelay() {
      this.$emit('setLoadingRelay', false)
      this.relayText = ''
      clearInterval(this.timer)
      this.countdownSec = 61
    },
    async setClassRelay() {
      this.dialog = false
      this.relayMode = this.bindRelayMode
      this.$emit('setLoadingRelay', true)
      try {
        this.relayText = '控制指令傳送中'
        // if (!this.differentRelayModeClassrooms.length) {
        //   this.$emit('setLoadingRelay', false)
        //   this.relayText = ''
        // } else {
        this.checkWhenLoading()
        await CardReader.setGroupRelayMode(this.group.id, this.relayMode)
        // this.showMsg({
        //   message: '設定完成',
        //   closable: true,
        //   time: 3000
        // })
        // }
      } catch (error) {
        console.error(error)
        if (error.response.status === 502 || error.response.status === 504) {
          // this.showMsg({
          //   message: '設定完成',
          //   closable: true,
          //   time: 3000
          // })
        } else {
          let errorMessage = '設定失敗'
          if (error.response && error.response.data.errorMessage) {
            errorMessage = error.response && error.response.data.errorMessage
          }
          this.showMsg({
            message: errorMessage,
            closable: true,
            time: 3000,
            color: 'error'
          })
          this.relayMode = null
          this.resetRelay()
        }
      }
    },
    async checkWhenLoading() {
      this.setUpdatingClassroomIds({
        classrooms: this.classroomsInTheGroup,
        mode: this.relayMode
      })
      this.timer = setInterval(() => {
        if (this.countdownSec === 45) this.relayText = '設備狀態回傳中'
        if (this.countdownSec) this.countdownSec -= 1
        else {
          clearInterval(this.timer)
          this.countdownSec = 61
        }
      }, 1000)
    },
    over20min(ts) {
      return new Date() - new Date(ts) > 20 * 60 * 1000
    }
  }
}
</script>
