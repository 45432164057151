<template>
  <div class="taiseia-fan-option">
    <v-slider
      :value="selectedIndex"
      @change="selectSlider"
      :tick-labels="ticksLabels"
      :tick-size="15"
      :max="maxLength"
      :disabled="disabled"
      step="1"
      ticks="always"
    >
    </v-slider>
  </div>
</template>

<script>
export default {
  name: 'TaiseiaFanOption',
  mixins: [],
  components: {},
  props: {
    value: {
      default: 10
    },
    maxLength: {
      type: Number,
      default: 15
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      fanValue: this.value
    }
  },
  computed: {
    ticksOption() {
      const ticksItems = [
        {
          text: '自動',
          value: 'FAN_0',
          acResponseValue: 0
        }
      ]
      for (let i = 1; i <= this.maxLength; i++) {
        ticksItems.push({
          text: String(i),
          value: `FAN_${i}`,
          acResponseValue: i
        })
      }
      return ticksItems
    },
    ticksLabels() {
      return this.ticksOption.map((d) => d.text)
    },
    selectedIndex() {
      return this.ticksOption.findIndex((item) => {
        return item.value === this.value
      })
    }
    // selectedIndex: {
    //   get: function () {
    //     return this.ticksOption.findIndex((item) => {
    //       return item.value === this.value
    //     })
    //   },
    //   set: function (val) {
    //     this.fanValue = this.ticksOption[val].value
    //   }
    // }
  },
  watch: {
    value: function (val) {
      this.fanValue = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    selectSlider(index) {
      this.fanValue = this.ticksOption[index].value
      this.commit()
    },
    commit() {
      this.$emit('input', this.fanValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.taiseia-fan-option ::v-deep {
  .v-slider__tick-label {
    padding-left: 10px;
  }
}
</style>
