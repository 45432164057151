<template>
  <v-card
    :class="[
      'ACStatus',
      { active: active, 'd-flex align-center justify-center': isIRAc }
    ]"
    elevation="0"
    v-ripple="{ class: `primary--text` }"
    outlined
    width="150px"
    rounded="xl"
    height="calc(100% - 48px)"
  >
    <v-card-subtitle
      class="d-flex flex-column align-center text-center accent--text pa-2"
    >
      <v-img
        v-if="isIRAc"
        contain
        max-height="20"
        max-width="20"
        class="mb-2"
        :src="require('@/assets/image/acControl/ir.png')"
      ></v-img>
      <v-img
        v-else
        max-height="32"
        max-width="32"
        src="@/assets/image/icon_AC.svg"
      ></v-img>
      <div>
        {{ name || '冷氣設備' }}
      </div>
      <div class="text-caption">
        {{ data.serialNumber || 'null' }} | {{ data.modbusId || 'null' }}
      </div>
    </v-card-subtitle>
    <template v-if="!isIRAc">
      <v-divider></v-divider>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-linear>
      <v-card-text class="py-2">
        <v-row dense no-gutters>
          <v-col align="center">
            <div class="fill-height d-flex align-center justify-center">
              <v-icon
                x-small
                :color="data.status === 'POWER_ON' ? 'success' : 'error'"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <div v-if="data.status !== 'POWER_ON'" class="grey--text ml-1">
                OFF
              </div>
            </div>
          </v-col>
          <template v-if="data.status === 'POWER_ON'">
            <v-col v-if="mode" align="center">
              <div :title="mode.text">
                <v-icon size="sm" color="primary"> {{ mode.icon }} </v-icon>
              </div>
            </v-col>
            <v-col v-if="temperature" align="center">
              <div class="subtitle-1 primary--text">
                {{ temperature.text }}
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ACStatus',
  props: {
    active: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => 'AC001'
    },
    data: {
      default: () => ({
        status: 'POWER_OFF',
        mode: 'FUNCTION_COOL',
        temperature: 'TEMP_22'
      })
    },
    isIRAc: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption'
    }),
    mode() {
      return this.modeOption.find((d) => d.value === this.data.mode) || null
    },
    temperature() {
      return (
        this.temperatureOption.find(
          (d) => d.acResponseValue === String(this.data.temperature)
        ) || null
      )
    }
    // disabledAc() {
    //   if (!this.data.deviceAlive || this.checkOver20min(this.data.ts))
    //     return true
    //   return false
    // }
  },
  methods: {
    checkOver20min(ts) {
      return new Date() - new Date(ts) > 20 * 60 * 1000
    }
  }
}
</script>
<style lang="scss" scoped>
.ACStatus {
  cursor: pointer;
  border-color: var(--v-primary-base);
  background: rgb(255 255 255 / 10%);
  &.active {
    border-width: 2px;
    // background: var(--v-primary-base);
    background: rgb(0 114 245 / 10%);
  }
  hr {
    border-color: var(--v-primary-base);
  }
  &.disabled {
    border-color: var(--v-ems-blue-grey-base);
    hr {
      border-color: var(--v-ems-blue-grey-base);
    }
  }
}
</style>
