var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ACControl mt-2",attrs:{"elevation":"0","width":"100%","color":"rgba(255, 255, 255, 0)"}},[_c('v-overlay',{attrs:{"absolute":"","opacity":"0.2","value":_vm.loadingSetAc}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1),_c('v-list',{attrs:{"color":"rgba(255, 255, 255, 0)"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" 開關 ")]),_c('div',{staticClass:"ml-4 mb-4"},[(!_vm.loadingSwitchAcText)?_c('v-switch',{attrs:{"inset":"","color":"primary","hide-details":"","label":_vm.status ? '運轉中' : '關機中'},on:{"change":_vm.updateStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_c('div',{staticClass:"mt-3"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"30","color":"grey","width":"2"}},[(_vm.countdownSec < 61)?_c('span',[_vm._v(_vm._s(_vm.countdownSec))]):_vm._e()]),_c('span',{staticClass:"ml-3 grey--text"},[_vm._v(_vm._s(_vm.loadingSwitchAcText))])],1)],1)],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" 模式 ")]),_c('v-item-group',{attrs:{"active-class":"primary white--text","mandatory":"","value":_vm.mode}},[_c('div',{staticClass:"d-flex mt-4"},_vm._l((_vm.modeOption),function(opt,modeIndex){return _c('div',{key:opt.value,staticClass:"mr-4"},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-card',{class:[
                    'd-flex align-center mode-control white--text',
                    { primary: active && _vm.status },
                    { 'ems-pale-grey': !_vm.status }
                  ],attrs:{"elevation":"0","width":"70px","height":"70px"},on:{"click":function($event){return _vm.updateMode(modeIndex)}}},[_c('div',{staticClass:"text-center ma-auto"},[(opt.icon)?_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(opt.icon)+" ")]):_vm._e(),(opt.image)?_c('v-img',{attrs:{"contain":"","height":"24","src":opt.image}}):_vm._e(),_c('div',{staticClass:"ma-1 subtitle-2"},[_vm._v(" "+_vm._s(opt.text)+" ")])],1)])]}}],null,true)})],1)}),0)])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold d-flex justify-space-between"},[_c('div',{},[_vm._v("溫度")]),(!_vm.status)?_c('div',{staticClass:"primary--text"},[_vm._v("--")]):_vm._e(),(_vm.status && _vm.temperature > -1)?_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.temperatureOption[_vm.temperature].text || '')+" ")]):_vm._e()]),_c('div',{staticClass:"mt-4 px-3 tmp-control"},[(!_vm.$vuetify.breakpoint.xs)?_c('v-slider',{attrs:{"disabled":!_vm.status ||
              !['FUNCTION_COOL', 'FUNCTION_HEATER'].includes(_vm.ac.mode),"value":_vm.temperature,"tick-labels":_vm.temperatureOptionSlide,"min":"0","max":_vm.temperatureOption.length - 1,"ticks":"always","color":"primary","tick-size":_vm.temperatureOption.length},on:{"change":function($event){return _vm.updateTemp($event)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(props){return [_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.getTemp(props.value))+" ")])]}}],null,false,2775935659)}):_c('v-select',{attrs:{"disabled":!_vm.status ||
              !['FUNCTION_COOL', 'FUNCTION_HEATER'].includes(_vm.ac.mode),"value":_vm.temperature,"items":_vm.temperatureOptionSlide,"outlined":"","color":"primary","background-color":"white"},on:{"change":function($event){return _vm.updateTemp($event)}}})],1)],1)],1),(!_vm.updateValue.fantaiseiamax || _vm.updateValue.fantaiseiamax === 0)?[_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" 風量 ")]),_c('v-item-group',{attrs:{"active-class":"primary white--text","value":_vm.fan}},[_c('div',{staticClass:"d-flex mt-4"},_vm._l((_vm.fanOption),function(opt,fanIndex){return _c('v-item',{key:opt.value,staticClass:"mr-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-btn',{class:[
                    'mode-control white--text',
                    { primary: active && _vm.status },
                    { 'ems-pale-grey': !_vm.status }
                  ],attrs:{"elevation":"0","width":"70px","height":"70px"},on:{"click":function($event){return _vm.updateFan(fanIndex)}}},[_vm._v(" "+_vm._s(opt.text)+" ")])]}}],null,true)})}),1)])],1)],1)]:_vm._e(),(_vm.updateValue.fantaiseiamax && _vm.updateValue.fantaiseiamax > 0)?[_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" 風量 ")]),_c('div',{staticClass:"mt-4 px-3 tmp-control"},[_c('TaiseiaFanSlider',{attrs:{"maxLength":Number(_vm.updateValue.fantaiseiamax),"disabled":!_vm.status},model:{value:(_vm.fantaiseia),callback:function ($$v) {_vm.fantaiseia=$$v},expression:"fantaiseia"}})],1)],1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }