<template>
  <div>
    <v-dialog v-if="relay" v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar dense flat color="transparent">
          <v-sheet
            class="d-flex justify-space-between align-center"
            color="transparent"
            width="100%"
          >
            <div class="d-flex align-center">
              <v-btn
                color="#00ddcb"
                v-bind="attrs"
                v-on="on"
                class="accent--text"
                :loading="
                  (loadingSetRelay && countdownSec > 60) || phase2Loading
                "
                :disabled="disabledRelaySwitch"
              >
                <span v-if="countdownSec <= 60 && countdownSec > 0">
                  {{ countdownSec }}
                </span>
                <span v-else> 電驛開關 </span>
              </v-btn>
              <div class="ml-2 error--text" v-if="phase2Loading">
                尚在等待裝置更新
              </div>
              <div class="ml-2 grey--text" v-else>
                <template v-if="relayText">
                  {{ relayText }}
                </template>
                <template v-else-if="relayData">
                  已選擇「<span>{{ relayData.name }}</span
                  >」
                </template>
              </div>
            </div>
            <div class="ml-2">
              <div>
                電錶 - 更新時間:
                {{
                  moment(classroom.classroomMeters[0].ts).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                }}
                | {{ classroom.classroomMeters[0].deviceAlive }}
              </div>
              <div>
                卡機 - 更新時間:
                {{
                  moment(classroom.cardReaders[0].ts).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                }}
                | {{ classroom.cardReaders[0].deviceAlive }}
              </div>
            </div>
          </v-sheet>
        </v-toolbar>
      </template>
      <v-lazy>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title class="accent--text font-weight-bold"
              >選擇由電驛來控制冷氣</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                dialog = false
                bindRelayMode = selectedRelayMode
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item-group v-model="bindRelayMode">
              <v-list-item
                v-for="(item, i) in relayActionOption"
                :key="i"
                three-line
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <div class="text--secondary">{{ item.desc }}</div>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="setClassRelay()" class="px-10">
              確認
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-lazy>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardReader from '@/api/ems/CardReader'
import Device from '@/api/ems/Device'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))
const checkOver20min = (ts) => {
  return new Date() - new Date(ts) > 20 * 60 * 1000
}

export default {
  name: 'ActionClassRelayControl',
  props: {
    classroom: {
      type: Object,
      default: () => {}
    },
    operatingAc: {
      type: Boolean
    },
    loadingSetRelay: {
      type: Boolean,
      default: false
    },
    phase2Loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      relay: null,
      relayText: '',
      selectedRelayMode: null,
      bindRelayMode: null,
      countdownSec: 61
    }
  },
  computed: {
    ...mapGetters({
      relayActionOption: 'ac/relayActionOption',
      updatingClassroomIds: 'action/updatingClassroomIds'
    }),
    relayData() {
      if (!this.relay) {
        return null
      }
      return this.relayActionOption.find((item) => {
        return this.selectedRelayMode === item.value
      })
    },
    relayButtonText() {
      if (
        this.classroom.classroomMeters.find(({ deviceAlive }) => !deviceAlive)
      ) {
        return '電表斷線'
      }

      const over20minMeterClassroom = this.classroom.classroomMeters.find(
        ({ ts }) => checkOver20min(ts)
      )
      if (over20minMeterClassroom) {
        const lastUpdateTime = moment(over20minMeterClassroom.ts).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        this.relayText = `最後更新時間 ${lastUpdateTime}`
        return '電表狀態未知'
      }

      if (this.classroom.cardReaders.find(({ deviceAlive }) => !deviceAlive)) {
        return '卡機斷線'
      }

      const over20minCardreaderClassroom = this.classroom.cardReaders.find(
        ({ ts }) => checkOver20min(ts)
      )
      if (over20minCardreaderClassroom) {
        const lastUpdateTime = moment(over20minCardreaderClassroom.ts).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        this.relayText = `最後更新時間 ${lastUpdateTime}`
        return '卡機狀態未知'
      }
      return this.relay.mode === null ? '電驛斷訊' : '電驛開關'
    },
    disabledRelaySwitch() {
      return this.closingAC || this.loadingSetRelay || this.phase2Loading
    }
  },
  watch: {
    classroom: {
      deep: true,
      handler() {
        this.init()
      }
    },
    updatingClassroomIds: {
      deep: true,
      handler(val) {
        if (val.length) {
          let exist = val.find((cls) => cls.classroomId === this.classroom.id)
          if (exist) {
            this.updatingAndCheck()
          }
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      updateClassroom: 'action/updateClassroom',
      finishClassroomUpdating: 'action/finishClassroomUpdating',
      setUpdatingClassroomIds: 'action/setUpdatingClassroomIds'
    }),
    init() {
      if (this.classroom.cardReaders) {
        this.relay =
          (this.classroom?.cardReaders.length &&
            this.classroom.cardReaders[0].relay) ||
          {}
        this.selectedRelayMode = this.relay.mode
        this.bindRelayMode = this.selectedRelayMode
        // todo
        // this.$emit('setRelayControllable', this.relayButtonText === '電驛開關')
        this.$emit('setRelayControllable', true)
      }
    },
    async updatingAndCheck() {
      if (!this.loadingSetRelay) {
        this.$emit('setRelayLoading', true)
        this.relayText = '控制指令傳送中'
        const timer = setInterval(() => {
          if (this.countdownSec === 45) this.relayText = '設備狀態回傳中'
          if (this.countdownSec) this.countdownSec -= 1
          else {
            clearInterval(timer)
            this.countdownSec = 61
          }
        }, 1000)
        const result = await this.checkClassroomInfoTimes()
        if (result) {
          clearInterval(timer)
          this.countdownSec = 61
        } else {
          // phase 2 to get new classroom info per 10 sec for 6 times
          this.$emit('setPhase2Loading', true)
          await this.checkClassroomInfoTimes(6, 10)
        }
        this.finishClassroomUpdating(this.classroom.id)
        this.relayText = ''
        this.$emit('setPhase2Loading', false)
        this.$emit('setRelayLoading', false)
      }
    },
    async setClassRelay() {
      this.dialog = false
      this.selectedRelayMode = this.bindRelayMode
      if (this.classroom.cardReaders[0].relay.mode !== this.selectedRelayMode) {
        this.setUpdatingClassroomIds({
          classrooms: [this.classroom],
          mode: this.selectedRelayMode
        })
        try {
          await CardReader.setRelayMode(
            this.classroom.cardReaders[0].id,
            this.selectedRelayMode
          )
          // 需跳設定成功?
        } catch (error) {
          if (error.response.status === 502 || error.response.status === 504) {
            // 需跳設定成功?
          } else {
            console.error(error)
            let errorMessage = '設定失敗'
            if (error.response && error.response.data.errorMessage) {
              errorMessage = error.response && error.response.data.errorMessage
            }
            this.showMsg({
              message: errorMessage,
              closable: true,
              time: 3000,
              color: 'error'
            })
            this.selectedRelayMode = this.relay.mode
            this.bindRelayMode = this.relay.mode
          }
        }
      }
    },
    async checkClassroomInfoTimes(times = 3, delaySeconds = 20) {
      if (!times) return false
      await delay(delaySeconds * 1000)
      const result = await this.getClassroomInfo()
      if (result) {
        return true
      } else {
        return await this.checkClassroomInfoTimes(times - 1, delaySeconds)
      }
    },
    async getClassroomInfo() {
      try {
        let mode = this.updatingClassroomIds.find(
          (cls) => cls.classroomId === this.classroom.id
        ).mode
        const { data } = await Device.getClassroomDevices(this.classroom.id)
        this.updateClassroom(data)
        console.log(`target: ${mode}`, `get: ${data.cardReaders[0].relay.mode}`)
        if (data.cardReaders[0].relay.mode === mode) {
          return true
        } else return false
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
}
</script>
