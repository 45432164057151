<template>
  <v-card
    elevation="0"
    width="100%"
    color="rgba(255, 255, 255, 0)"
    class="ACControl mt-2"
  >
    <v-list color="rgba(255, 255, 255, 0)">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 font-weight-bold">
            開關
          </v-list-item-title>
          <div class="d-flex align-center mt-4">
            <v-item-group active-class="primary white--text">
              <div class="d-inline-flex ems-blue-grey-two rounded pa-1">
                <div
                  v-for="(opt, index) in statusOption"
                  :key="opt.value"
                  :class="index === statusOption.length - 1 ? '' : 'mr-1'"
                >
                  <v-item :value="opt.value === 'POWER_ON'">
                    <v-card
                      class="
                        d-flex
                        align-center
                        status-control
                        noState-hover
                        white--text
                        ems-light-grey-blue
                      "
                      hover
                      width="62px"
                      height="62px"
                      @click="updateStatus(opt.value)"
                    >
                      <div class="text-center ma-auto">
                        <v-icon dark style="transform: rotate(90deg)">
                          {{ opt.icon }}
                        </v-icon>
                        <div class="ma-1 subtitle-2">{{ opt.text }}</div>
                      </div>
                    </v-card>
                  </v-item>
                </div>
              </div>
            </v-item-group>
            <v-progress-circular
              v-if="loadingSetAc"
              indeterminate
              size="35"
              color="grey"
              width="2"
              class="ml-6"
            >
              <span v-if="countdownSec < 61">{{ countdownSec }}</span>
            </v-progress-circular>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 font-weight-bold">
            模式
          </v-list-item-title>
          <v-item-group active-class="primary white--text" @change="updateMode">
            <div class="d-flex mt-4">
              <div v-for="opt in modeOption" :key="opt.value" class="mr-4">
                <v-item v-slot="{ toggle }">
                  <v-card
                    class="
                      d-flex
                      align-center
                      mode-control
                      noState-hover
                      white--text
                      ems-light-grey-blue
                    "
                    hover
                    width="70px"
                    height="70px"
                    @click="toggle"
                  >
                    <div class="text-center ma-auto">
                      <v-icon v-if="opt.icon" dark> {{ opt.icon }} </v-icon>
                      <v-img
                        contain
                        v-if="opt.image"
                        height="24"
                        :src="opt.image"
                      ></v-img>
                      <div class="ma-1 subtitle-2">
                        {{ opt.text }}
                      </div>
                    </div>
                  </v-card>
                </v-item>
              </div>
            </div>
          </v-item-group>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title
            class="subtitle-2 font-weight-bold d-flex justify-space-between"
          >
            <div class="">溫度</div>
          </v-list-item-title>
          <div class="mt-4 px-3 tmp-control">
            <v-slider
              v-if="!$vuetify.breakpoint.xs"
              :tick-labels="temperatureOptionSlide"
              min="0"
              :max="temperatureOption.length - 1"
              ticks="always"
              color="primary"
              :tick-size="temperatureOption.length"
              @change="updateTemp($event)"
            >
              <template v-slot:thumb-label="props">
                <div class="caption">
                  {{ getTemp(props.value) }}
                </div>
              </template>
            </v-slider>
            <v-select
              v-else
              :items="temperatureOptionSlide"
              outlined
              color="primary"
              background-color="white"
              @change="updateTemp($event)"
            ></v-select>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    loadingSetAc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countdownSec: 61,
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      statusOption: 'ac/statusOption',
      modeOption: 'ac/modeOption',
      temperatureOption: 'ac/temperatureOption',
      updatingAcs: 'action/updatingAcs'
    }),
    temperatureOptionSlide() {
      return this.temperatureOption.map((d) => d.text)
    }
  },
  watch: {
    loadingSetAc(val) {
      if (val) {
        this.timer = setInterval(() => {
          if (this.countdownSec) this.countdownSec -= 1
          else {
            clearInterval(this.timer)
            this.countdownSec = 61
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
        this.countdownSec = 61
      }
    }
  },
  methods: {
    getTemp(val) {
      return this.temperatureOptionSlide[val]
    },
    updateStatus(val) {
      this.setIR(val)
    },
    updateMode(val) {
      const type = this.modeOption[val]
      this.setIR(type.value)
    },
    updateTemp(val) {
      const type =
        this.temperatureOption[val].value || this.temperatureOption[0].value
      this.setIR(type)
    },
    setIR(value) {
      this.$emit('setIR', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.noState-hover {
  &:hover {
    background: var(--v-ems-blue-base) !important;
  }
}
.ACControl .mode-control {
  border-color: transparent;
  background: var(--v-ems-light-grey-blue-base);
}
</style>
