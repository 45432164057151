<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card
          rounded
          class="mx-auto ems-opacity-50-bg frosted-glass"
          elevation="0"
          height="100%"
          width="100%"
        >
          <v-card-title class="pb-2">
            <span class="subtitle-1 font-weight-bold accent--text"> 群組 </span>
            <v-spacer></v-spacer>
            <GroupAppliedHint />
          </v-card-title>
          <v-card-text v-if="loadingGroups" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-text>
          <v-card-text v-else-if="filteredGroups.length > 0">
            <v-expansion-panels flat focusable>
              <v-expansion-panel
                v-for="item in filteredGroups"
                :key="item.id"
                elevation="0"
                :class="[
                  'ac-expansion-panel',
                  {
                    'ir-control': item.type === 'IR_AIR_CON',
                    established: item.type === 'CARD_READER'
                  }
                ]"
                active-class="active"
                @change="selectItem(item, 'group')"
              >
                <!-- active -->
                <v-expansion-panel-header
                  expand-icon="mdi-arrow-down"
                  v-slot="{ open }"
                >
                  <div
                    :class="[
                      'd-flex  align-center font-weight-bold',
                      { 'white--text': open },
                      { ' accent--text': !open }
                    ]"
                  >
                    <span> {{ item.name }} </span>
                    <div class="ml-1">
                      <GroupApply :group="getOriginGroup(item)" />
                    </div>
                    <v-spacer></v-spacer>
                    <div
                      class="d-flex align-center justify-end mr-3"
                      v-if="item.statusMap"
                    >
                      <v-icon
                        class="mx-1"
                        x-small
                        :color="
                          ['PARTIALLY_ON', 'ON'].includes(
                            item.statusMap.AIRCONDITIONER
                          )
                            ? 'success'
                            : 'error'
                        "
                      >
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <div v-if="item.statusMap.AIRCONDITIONER === 'OFF'">
                        <div class="d-flex align-center justify-center mr-3">
                          <div
                            :class="[
                              'ml-2',
                              { 'white--text': open },
                              { 'grey--text': !open }
                            ]"
                          >
                            關閉
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex align-center justify-end mr-3"
                      v-if="false"
                    >
                      <v-icon
                        class="mx-1"
                        x-small
                        :color="
                          item.status.status === 'POWER_ON'
                            ? 'success'
                            : 'error'
                        "
                      >
                        mdi-checkbox-blank-circle
                      </v-icon>
                      <div v-if="item.status.status !== 'POWER_OFF'">
                        <div class="d-flex align-center justify-center mr-3">
                          <!-- <div style="width: 26px; height: 26px;" :class="['d-flex align-center pa-1 ioBtn', { 'white--svg': open}]">
                            <svg width="100%" height="100%" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.877 11C7.40923 16.175 4 23.4896 4 31.5978C4 47.2839 16.7599 60 32.5 60C48.2401 60 61 47.2839 61 31.5978C61 23.4896 57.5908 16.175 52.123 11" stroke-width="8" stroke-linecap="round" stroke-linejoin="bevel"/>
                              <line x1="33" y1="4" x2="33" y2="32" stroke-width="8" stroke-linecap="round"/>
                            </svg>
                          </div> -->
                          <div
                            :class="[
                              'ml-2',
                              { 'white--text': open },
                              { 'grey--text': !open }
                            ]"
                          >
                            關閉
                          </div>
                        </div>
                      </div>
                      <!-- 狀態無法得知 -->
                      <template v-if="item.status.status === 'POWER_ON'">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="mr-3"
                              v-if="getMode(item.status.mode)"
                              :title="getMode(item.status.mode).text"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon v-if="open" size="sm" color="white">
                                {{ getMode(item.status.mode).icon }}
                              </v-icon>
                              <v-icon v-else size="sm" color="accent">
                                {{ getMode(item.status.mode).icon }}
                              </v-icon>
                            </div>
                          </template>
                          <span>{{ getMode(item.status.mode).text }}</span>
                        </v-tooltip>
                        <div
                          class="mr-3"
                          v-if="getTemperature(item.status.temperature)"
                        >
                          <div class="subtitle-1">
                            {{ getTemperature(item.status.temperature).text }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <GroupControlContent
                    :group="item"
                    :classrooms="classrooms"
                    @reloadClassrooms="getClassrooms(tagId)"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text v-else class="text-center"> 無資料</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          rounded
          class="mx-auto ems-opacity-50-bg frosted-glass"
          elevation="0"
          height="100%"
          width="100%"
        >
          <v-card-title class="pb-2">
            <span class="subtitle-1 font-weight-bold accent--text">
              班級與專科教室
            </span>
          </v-card-title>
          <v-card-text v-if="loadingClassrooms" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-text>
          <v-card-text v-else-if="filteredRoom.length > 0">
            <v-expansion-panels flat focusable>
              <v-expansion-panel
                v-for="classroom in filteredRoom"
                :key="classroom.id"
                elevation="0"
                :class="[
                  'ac-expansion-panel',
                  {
                    'ir-control': isIRClassroom(classroom),
                    established: isEstablishedClassroom(classroom)
                  }
                ]"
                active-class="active"
                @change="selectItem(classroom, 'room')"
              >
                <v-expansion-panel-header
                  expand-icon="mdi-arrow-down"
                  v-slot="{ open }"
                >
                  <v-row
                    :class="[
                      'd-flex align-center justify-space-between font-weight-bold',
                      { 'white--text': open },
                      { ' accent--text': !open }
                    ]"
                  >
                    <v-col cols="7">
                      <span>
                        {{ classroom.name }}
                      </span>
                    </v-col>
                    <v-col cols="2">
                      <div>{{ relayModeText(classroom) }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div
                        class="d-flex align-center justify-end mr-3"
                        v-if="!classroom.airConditioners"
                      >
                        <div
                          :class="[
                            'ml-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]"
                        >
                          無冷氣資料
                        </div>
                      </div>
                      <div class="d-flex align-center justify-end mr-3" v-else>
                        <div class="d-flex align-center justify-center">
                          <v-img
                            v-if="isIRClassroom(classroom)"
                            contain
                            max-height="18"
                            max-width="18"
                            :src="
                              open
                                ? require('@/assets/image/acControl/ir-expand-panel.png')
                                : require('@/assets/image/acControl/ir.png')
                            "
                          ></v-img>
                          <v-icon
                            v-else-if="isEstablishedClassroom(classroom)"
                            class="mx-1"
                            x-small
                            :color="
                              cardReaderIsOn(classroom) ? 'success' : 'error'
                            "
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            v-else
                            class="mx-1"
                            x-small
                            :color="
                              acState(classroom.airConditioners).on > 0
                                ? 'success'
                                : 'error'
                            "
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                        </div>
                        <div
                          v-if="isIRClassroom(classroom)"
                          :class="[
                            'mx-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]"
                        >
                          IR控制
                        </div>
                        <div
                          v-if="isEstablishedClassroom(classroom)"
                          :class="[
                            'mx-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]"
                        >
                          既設冷氣
                        </div>
                        <div
                          v-else-if="acState(classroom.airConditioners).on > 0"
                          class="ml-2"
                        >
                          {{ acState(classroom.airConditioners).on }} /
                          {{ acState(classroom.airConditioners).total }}
                        </div>
                        <div
                          v-else
                          :class="[
                            'mx-2',
                            { 'white--text': open },
                            { 'grey--text': !open }
                          ]"
                        >
                          關閉
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content v-if="!classroom.airConditioners">
                  <div class="grey--text text-center mt-3 pa-3">無冷氣資料</div>
                </v-expansion-panel-content>
                <v-expansion-panel-content class="pt-3" v-else eager>
                  <ClassroomControlContent :classroom="classroom" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text v-else class="text-center"> 無資料</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClassroomControlContent from '@/components/Action/ClassroomControlContent'
import GroupControlContent from '@/components/Action/GroupControlContent'
import GroupAppliedHint from '@/views/school/groupApplied/Hint'
import GroupApply from '@/views/school/groupApplied/Apply'

export default {
  components: {
    GroupControlContent,
    ClassroomControlContent,
    GroupAppliedHint,
    GroupApply
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 預設有兩台
      selectedGroup: null,
      selectedRoom: null
    }
  },
  computed: {
    ...mapGetters({
      groups: 'action/groups',
      classrooms: 'action/classrooms',
      loadingGroups: 'action/loadingGroups',
      loadingClassrooms: 'action/loadingClassrooms',
      originGroups: 'groups/groups'
    }),
    search() {
      return this.$route.query.search || ''
    },
    filteredGroups() {
      /** 群組 type
          NULL:未指定
          METER:電表
          AIR_CON:冷氣
          IR_AIR_CON:IR冷氣
          CARD_READER:卡機
          DEPOSIT_SYSTEM:儲值機
       */
      return this.groups
        .filter(({ type }) =>
          ['AIR_CON', 'IR_AIR_CON', 'CARD_READER'].includes(type)
        )
        .filter(({ name }) => {
          return name.indexOf(this.search) > -1
        })
    },
    filteredRoom() {
      return this.classrooms.filter(
        ({ name, classroomMeters, cardReaders }) => {
          return (
            name.indexOf(this.search) > -1 &&
            classroomMeters.length &&
            cardReaders.length
          )
        }
      )
    }
  },
  async mounted() {
    await this.getOriginGroups(this.tagId)
    this.getGroups(this.tagId)
    this.getClassrooms(this.tagId)
  },
  destroyed() {
    this.clearClassroomUpdating()
  },
  methods: {
    ...mapActions({
      getGroups: 'action/getGroups',
      getClassrooms: 'action/getClassrooms',
      clearClassroomUpdating: 'action/clearClassroomUpdating',
      getOriginGroups: 'groups/getGroups'
    }),
    acState(item) {
      const on = item.reduce((count, d) => {
        if (d.status === 'POWER_ON') count++
        return count
      }, 0)
      return {
        on,
        off: item.length - on || 0,
        total: item.length
      }
    },
    selectItem(item, type) {
      switch (type) {
        case 'group':
          if (this.selectedGroup && item.id === this.selectedGroup.id) {
            this.selectedGroup = null
          } else {
            this.selectedGroup = item
          }
          break
        default:
          if (this.selectedRoom && item.id === this.selectedRoom.id) {
            this.selectedRoom = null
          } else {
            this.selectedRoom = item
          }
          break
      }
    },
    relayModeText(classroom) {
      let relayMode = classroom?.cardReaders.length
        ? classroom.cardReaders[0].relay?.mode
        : null
      switch (relayMode) {
        case 0:
          return '斷開電驛'
        case 1:
          return '接上電驛'
        case 2:
          return '交由卡機控制'
        default:
          return '無電驛狀態'
      }
    },
    isIRClassroom(classroom) {
      return !!classroom?.irAirConditioners?.length
    },
    isEstablishedClassroom(classroom) {
      return classroom?.type === 'EXISTING_AIR_CON'
    },
    cardReaderIsOn(classroom) {
      return classroom?.cardReaders[0]?.relay?.status
    },
    getOriginGroup(group) {
      return this.originGroups.find(({ id }) => id === group.id)
    }
  }
}
</script>

<style scoped lang="scss">
>>> .v-slider__tick {
  border-radius: 50%;
}
>>> .v-slider__tick:not(.v-slider__tick--filled) {
  background: rgb(25 118 210 / 35%);
}
.AC-slider {
  width: 100%;
  // max-width: 100px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}
*::v-deep {
  .v-expansion-panels {
    .v-expansion-panel.ac-expansion-panel {
      background-color: var(--v-ems-pale-blue-base);
      margin-bottom: 2px;
      &.ir-control {
        background: #f6b40033;
      }
      &.established {
        background: #52ad2a33;
      }
      &.v-expansion-panel--active {
        background: hsla(0, 0%, 100%, 0) !important;
        border-radius: 5px;
        border: 1px solid var(--v-primary-base);
      }
      .v-expansion-panel-header--active {
        background-color: var(--v-primary-base);
        border-color: var(--v-primary-base);
      }
    }
  }
}
</style>
