<template>
  <div>
    <ClassRelayControl
      class="mb-1"
      :classroom="classroom"
      :loadingSetRelay="relayLoading"
      :phase2Loading="phase2Loading"
      @setRelayControllable="relayControllable = $event"
      @setRelayLoading="relayLoading = $event"
      @setPhase2Loading="phase2Loading = $event"
      :operatingAc="hasOperatingAc"
    />
    <div v-if="classroom.type === 'EXISTING_AIR_CON'"></div>
    <div v-else-if="cardUnavaliable" class="error--text mt-2 px-4">*未插卡</div>
    <div v-else-if="acControllable">
      <div class="font-weight-bold sencondary--text pt-1 pb-3">
        選擇要操控的冷氣
      </div>
      <v-row>
        <v-col cols="12">
          <vue-scroll>
            <div class="AC-slider">
              <div
                class="mr-2"
                v-for="ac in mixAirConditioners"
                :key="ac.id"
                @click="clickAc(ac)"
              >
                <!-- todo -->
                <!-- :style="disabledAc(ac) ? 'cursor:not-allowed' : ''" -->
                <ACStatus
                  :name="ac.name"
                  :data="ac"
                  :active="selectedAC && selectedAC.id === ac.id"
                  :loading="loadingAC && selectedAC && selectedAC.id === ac.id"
                  :isIRAc="ac.type === 'IR_AIRCONDITIONER'"
                />
                <div class="text-center mt-2 text-caption grey--text">
                  <!-- <span v-if="!ac.deviceAlive"> 冷氣斷線 </span>
                  <span v-else-if="over20min(ac)">
                    冷氣狀態未知<br />最後更新時間<br />
                    {{ moment(ac.ts).format('YYYY-MM-DD HH:mm:ss') }}
                  </span> -->
                  更新時間<br />
                  {{ moment(ac.ts).format('YYYY-MM-DD HH:mm:ss') }} |
                  {{ ac.deviceAlive }}
                </div>
              </div>
            </div>
          </vue-scroll>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-for="ac in classroom.irAirConditioners" :key="ac.id">
            <IRControl
              v-show="selectedAC && selectedAC.id === ac.id"
              :controlId="ac.id"
            />
          </div>
          <div v-for="ac in classroom.airConditioners" :key="ac.id">
            <ACControl
              v-show="selectedAC && selectedAC.id === ac.id"
              :ac="ac"
              :classroomId="classroom.id"
              @operateAc="operateAc"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ACControl from '@/components/Action/ACControl'
import ACStatus from '@/components/Action/ACStatus'
import ClassRelayControl from '@/components/Action/ClassRelayControl'
import IRControl from '@/components/Action/IRControl'

const checkOver20min = (ts) => {
  return new Date() - new Date(ts) > 20 * 60 * 1000
}

export default {
  components: {
    ACStatus,
    ACControl,
    ClassRelayControl,
    IRControl
  },
  props: {
    classroom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedAC: null,
      loadingAC: false,
      relayControllable: false,
      relayLoading: false,
      phase2Loading: false,
      operatingAcList: []
    }
  },
  computed: {
    acControllable() {
      return (
        this.relayControllable &&
        !this.relayLoading &&
        this.classroom.cardReaders.length &&
        this.classroom.cardReaders[0].relay?.mode !== 0
      )
    },
    hasOperatingAc() {
      return this.operatingAcList.length > 0
    },
    cardUnavaliable() {
      return (
        this.classroom.cardReaders.length &&
        this.classroom.cardReaders[0].relay?.status === 0 &&
        this.classroom.cardReaders[0].relay?.mode === 2
      )
    },
    mixAirConditioners() {
      return []
        .concat(
          this.classroom.irAirConditioners.map((ac) => ({
            ...ac,
            type: 'IR_AIRCONDITIONER'
          }))
        )
        .concat(
          this.classroom.airConditioners.map((ac) => ({
            ...ac,
            type: 'AIRCONDITIONER'
          }))
        )
    }
  },
  methods: {
    setRelayData(relayData) {
      this.relay = relayData
    },
    operateAc(payload) {
      if (payload.status)
        this.operatingAcList = this.operatingAcList.concat([payload.id])
      else
        this.operatingAcList = this.operatingAcList.filter(
          (id) => payload.id !== id
        )
    },
    clickAc(ac) {
      this.selectedAC = ac
      //todo
      // if (!this.disabledAc(ac)) {
      //   this.selectedAC = ac
      // }
    },
    disabledAc(ac) {
      if (!ac.deviceAlive || checkOver20min(ac.ts)) return true
      return false
    },
    over20min(ac) {
      return checkOver20min(ac.ts)
    }
  }
}
</script>

<style lang="scss" scoped>
.AC-slider {
  width: 100%;
  // max-width: 100px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
}
</style>
