<template>
  <div>
    <GroupRelayControl
      class="mt-3"
      :group="group"
      :loadingSetRelay="loadingSetRelay"
      @setLoadingRelay="loadingSetRelay = $event"
      @setRelayMode="relayMode = $event"
      @setRelaySuccess="setRelaySuccess = $event"
    />
    <v-overlay absolute opacity="0.2" :value="loadingSetRelay || loadingSetAc">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
    <!-- 群組冷氣狀態無法得知 -->
    <div v-if="!loadingSetRelay">
      <div
        class="mt-5"
        v-if="uncontrollableAcsInTheGroup.length && group.type === 'AIR_CON'"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="mt-5">
              <v-icon class="mr-1" small>mdi-alert-outline</v-icon>
              查看排除不可控冷氣機
            </span>
          </template>
          <span>
            <div>以下冷氣機將不受群組冷氣控制</div>
            <template v-for="ac in uncontrollableAcsInTheGroup">
              <v-icon color="white">mdi-circle-small</v-icon>
              {{ ac.classroomName }} | {{ ac.name }}<br />
            </template>
          </span>
        </v-tooltip>
      </div>
      <!-- <div v-if="!hasControllableAcsInTheGroup" class="mt-2">
        <span v-if="relayMode === null">
          因群組內未插卡或冷氣狀態異常或斷開電驛，所以冷氣皆無法控制
        </span>
        <span v-if="relayMode === 1 && setRelaySuccess">
          因群組內冷氣狀態皆異常，所以冷氣皆無法控制
        </span>
        <span v-if="relayMode === 2 && setRelaySuccess">
          因群組內未插卡或冷氣狀態異常，所以冷氣皆無法控制
        </span>
      </div> -->
      <IRControl
        v-if="group.type === 'IR_AIR_CON'"
        isGroup
        :controlId="group.id"
      />
      <div v-else-if="group.type === 'CARD_READER'"></div>
      <GroupAcControl
        v-else
        :id="group.id"
        no-state
        @setIR="controlGroupAc"
        :loadingSetAc="loadingSetAc"
      />
    </div>
  </div>
</template>

<script>
import GroupAcControl from '@/components/Action/GroupAcControl'
import GroupRelayControl from '@/components/Action/GroupRelayControl'
import IRControl from '@/components/Action/IRControl'
import { mapGetters, mapActions } from 'vuex'
import AC from '@/api/ems/AC'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))
const checkUnControllable = ({ classroomMeters, cardReaders }) => {
  if (classroomMeters.find(({ deviceAlive }) => !deviceAlive)) return true
  if (classroomMeters.find(({ ts }) => checkOver20min(ts))) return true
  if (cardReaders.find(({ deviceAlive }) => !deviceAlive)) return true
  if (cardReaders.find(({ ts }) => checkOver20min(ts))) return true
  return false
}
const checkOver20min = (ts) => {
  return new Date() - new Date(ts) > 20 * 60 * 1000
}
const classroomNameAndAcName = (classrooms) => {
  return classrooms.reduce(
    (acc, curr) =>
      acc.concat(
        curr.airConditioners.reduce((acc, { name, id }) => {
          return acc.concat([
            {
              classroomName: curr.name,
              name: name,
              id: id
            }
          ])
        }, [])
      ),
    []
  )
}

export default {
  components: {
    GroupRelayControl,
    GroupAcControl,
    IRControl
  },
  props: {
    group: {
      type: Object,
      default: () => {}
    },
    classrooms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loadingSetRelay: false,
      loadingSetAc: false,
      relayMode: null,
      setRelaySuccess: false
    }
  },
  computed: {
    ...mapGetters({
      updatingAcs: 'action/updatingAcs'
    }),

    acsInTheGroup() {
      return this.classroomsInTheGroup.reduce(
        (acc, curr) => acc.concat(curr.airConditioners),
        []
      )
    },

    controllableAcsInTheGroup() {
      return this.classroomsInTheGroup.reduce(
        (acc, curr) =>
          acc.concat(
            curr.airConditioners.filter(
              ({ id }) =>
                !this.uncontrollableAcsInTheGroup.find((ac) => ac.id === id)
            )
          ),
        []
      )
    },

    uncontrollableAcsInTheGroup() {
      // 排除不可控制教室的冷氣
      let acList = []
      acList = acList.concat(
        classroomNameAndAcName(this.uncontrollableClassroomsInTheGroup)
      )
      // 排除 relay status = 0 的教室冷氣
      let unControllableAcByRelayStatusClassrooms = this.classroomsInTheGroup
        .filter((element) => !checkUnControllable(element))
        .filter(({ cardReaders }) => !cardReaders[0].relay.status)
      acList = acList.concat(
        classroomNameAndAcName(unControllableAcByRelayStatusClassrooms)
      )
      // 排除 unalive || over20min 冷氣
      let unAliveOrOver20minAcList = this.classroomsInTheGroup
        .filter((element) => !checkUnControllable(element))
        .filter(({ cardReaders }) => cardReaders[0].relay.status)
        .reduce(
          (acc, curr) =>
            acc.concat(
              curr.airConditioners.reduce(
                (acc, { deviceAlive, ts, name, id }) => {
                  if (!deviceAlive || checkOver20min(ts))
                    return acc.concat([
                      {
                        classroomName: curr.name,
                        name: name,
                        id: id
                      }
                    ])
                  return acc
                },
                []
              )
            ),
          []
        )
      acList = acList.concat(unAliveOrOver20minAcList)

      return acList
    },

    // groupRelayControllable() {
    //   let controllableClassroomsInTheGroup = this.classrooms
    //     .filter(({ id }) => this.group.classroomIds.includes(id))
    //     .filter((element) => !checkUnControllable(element))
    //   return controllableClassroomsInTheGroup.length
    // },

    hasControllableAcsInTheGroup() {
      return (
        this.classroomsInTheGroup.reduce((acc, { airConditioners }) => {
          return acc + airConditioners.length
        }, 0) > this.uncontrollableAcsInTheGroup.length
      )
    },

    uncontrollableClassroomsInTheGroup() {
      let unControllableClassroom = this.classroomsInTheGroup.filter(
        (element) => checkUnControllable(element)
      )
      return unControllableClassroom
    },

    classroomsInTheGroup() {
      return this.classrooms.filter(({ id }) =>
        this.group.classroomIds.includes(id)
      )
    },

    hasUpdatingAcsInTheGroup() {
      return this.updatingAcs.some(({ id }) =>
        this.classroomsInTheGroup.find(({ airConditioners }) =>
          airConditioners.find((ac) => ac.id === id)
        )
      )
    }
  },
  watch: {
    hasUpdatingAcsInTheGroup(val) {
      if (!val) this.loadingSetAc = val
    }
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      setUpdatingAcs: 'action/setUpdatingAcs'
    }),

    async controlGroupAc(type) {
      if (this.acsInTheGroup.length) {
        this.setUpdatingAcs({
          acs: this.acsInTheGroup,
          type: type
        })
        this.loadingSetAc = true
        try {
          await AC.controlGroupAc(this.group.id, type)
        } catch (error) {
          let errorMessage = '設定失敗'
          if (error.response && error.response.data.errorMessage) {
            errorMessage = error.response && error.response.data.errorMessage
          }
          this.showMsg({
            message: errorMessage,
            closable: true,
            time: 3000,
            color: 'error'
          })
        }
      }
    }
  }
}
</script>
