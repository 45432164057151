var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"accent--text",attrs:{"color":"#00ddcb","loading":_vm.loadingSetRelay && _vm.countdownSec > 60,"disabled":_vm.loadingSetRelay}},'v-btn',attrs,false),on),[(_vm.loadingSetRelay)?_c('span',[_vm._v(" "+_vm._s(_vm.countdownSec)+" ")]):_c('span',[_vm._v(" 電驛開關 ")])]),_c('div',{staticClass:"ml-2 grey--text"},[(_vm.relayText)?[_vm._v(" "+_vm._s(_vm.relayText)),_c('br')]:(_vm.relayMode !== null && _vm.isSuccess)?[_c('span',[_vm._v(_vm._s(_vm.selectRelayModeSuccessText))]),_c('br')]:_vm._e(),_vm._v(" 群組內教室電驛將會同步受控"),_c('br'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.uncontrollableClassroomsInTheGroup.length)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-alert-outline")]),_vm._v(" 查看排除不可控制教室 ")],1):_vm._e()]}}],null,true)},[_c('span',[_c('div',[_vm._v("以下班級將不受群組電驛控制")]),_vm._l((_vm.uncontrollableClassroomsInTheGroup),function(classroom){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-circle-small")]),_vm._v(" "+_vm._s(classroom.name)),_c('br')]})],2)])],2)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-lazy',[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"accent--text font-weight-bold"},[_vm._v(" 選擇由電驛來控制冷氣 ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false
              _vm.bindRelayMode = _vm.relayMode}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-list-item-group',{model:{value:(_vm.bindRelayMode),callback:function ($$v) {_vm.bindRelayMode=$$v},expression:"bindRelayMode"}},_vm._l((_vm.relayActionOption),function(item,i){return _c('v-list-item',{key:i,attrs:{"three-line":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('div',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.desc))])],1)]}}],null,true)})}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary"},on:{"click":function($event){return _vm.setClassRelay()}}},[_vm._v(" 確認 ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }